html,
html *,
html *:before,
html *:after {
    font-family: "Neuzit", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.cardCreation {
    &_buttons {
        display: flex;
        justify-content: space-evenly;
    }
}

// TODO get from frontend-ui-kit
// Font: Neuzeit
$neuzeit-cloudfront-font-path: '//dye1fo42o13sl.cloudfront.net/fonts/Neuzeit';
$primary-font-name: 'Neuzeit';

strong, b {
  font-weight: bold;
}

em, i {
  font-style: italic;
}

@font-face {
  font-family: $primary-font-name;
  src: url('#{$neuzeit-cloudfront-font-path}/NeuzeGroTReg02.woff2') format('woff2'), /* Modern Browsers */
  url('#{$neuzeit-cloudfront-font-path}/NeuzeGroTReg02.woff') format('woff'), /* Modern Browsers */
  url('#{$neuzeit-cloudfront-font-path}/NeuzeGroTReg02.ttf')  format('truetype'); /* Safari, Android, iOS */
  font-weight: normal;
  font-style: normal;
  font-display:block;
}

@font-face {
  font-family: $primary-font-name;
  src: url('#{$neuzeit-cloudfront-font-path}/NeuzeGroTReg02.woff2') format('woff2'), /* Modern Browsers */
  url('#{$neuzeit-cloudfront-font-path}/NeuzeGroTReg02.woff') format('woff'), /* Modern Browsers */
  url('#{$neuzeit-cloudfront-font-path}/NeuzeGroTReg02.ttf')  format('truetype'); /* Safari, Android, iOS */
  font-weight: 400;
  font-style: normal;
  font-display:block;
}

// TODO: deal with italic
@font-face {
  font-family: $primary-font-name;
  src: url('#{$neuzeit-cloudfront-font-path}/NeuzeGroTReg02.woff2') format('woff2'), /* Modern Browsers */
  url('#{$neuzeit-cloudfront-font-path}/NeuzeGroTReg02.woff') format('woff'), /* Modern Browsers */
  url('#{$neuzeit-cloudfront-font-path}/NeuzeGroTReg02.ttf')  format('truetype'); /* Safari, Android, iOS */
  font-weight: 400;
  font-style: italic;
  font-display:block;
}

@font-face {
  font-family: $primary-font-name;
  src: url('#{$neuzeit-cloudfront-font-path}/NeuzeGroTLig02.woff2') format('woff2'), /* Modern Browsers */
  url('#{$neuzeit-cloudfront-font-path}/NeuzeGroTLig02.woff') format('woff'), /* Modern Browsers */
  url('#{$neuzeit-cloudfront-font-path}/NeuzeGroTLig02.ttf')  format('truetype'); /* Safari, Android, iOS */
  font-weight: 300;
  font-style: normal;
  font-display:block;
}

@font-face {
  font-family: $primary-font-name;
  src: url('#{$neuzeit-cloudfront-font-path}/NeuzeGroTBol02.woff2') format('woff2'), /* Modern Browsers */
  url('#{$neuzeit-cloudfront-font-path}/NeuzeGroTBol02.woff') format('woff'), /* Modern Browsers */
  url('#{$neuzeit-cloudfront-font-path}/NeuzeGroTBol02.ttf')  format('truetype'); /* Safari, Android, iOS */
  font-weight: 700;
  font-style: normal;
  font-display:block;
}

@font-face {
  font-family: $primary-font-name;
  src: url('#{$neuzeit-cloudfront-font-path}/NeuzeGroTBla02.woff2') format('woff2'), /* Modern Browsers */
  url('#{$neuzeit-cloudfront-font-path}/NeuzeGroTBla02.woff') format('woff'), /* Modern Browsers */
  url('#{$neuzeit-cloudfront-font-path}/NeuzeGroTBla02.ttf')  format('truetype'); /* Safari, Android, iOS */
  font-weight: 900;
  font-style: normal;
  font-display:block;
}

// TODO: deal with italic light
@font-face {
  font-family: $primary-font-name;
  src: url('#{$neuzeit-cloudfront-font-path}/NeuzeGroTLig02.woff2') format('woff2'), /* Modern Browsers */
  url('#{$neuzeit-cloudfront-font-path}/NeuzeGroTLig02.woff') format('woff'), /* Modern Browsers */
  url('#{$neuzeit-cloudfront-font-path}/NeuzeGroTLig02.ttf')  format('truetype'); /* Safari, Android, iOS */
  font-weight: 300;
  font-style: italic;
  font-display:block;
}
